export const PAGES = {
    landing: {
        title: 'Bienvenu',
        url: '/',
    },
    notFound: {
        title: 'Oops!',
        url: '*',
    },
};

import { BsFillSuitcaseFill } from 'react-icons/bs';
import ImageAgrilycoop from '../assets/landing/projects/agrilycoop.png';
import ImageCooplab from '../assets/landing/projects/cooplab.png';
import ImageInvenboost from '../assets/landing/projects/invenboost.png';
import ImageSil from '../assets/landing/projects/sil.png';
import ImageVcu from '../assets/landing/projects/vcu.png';

import LogoAgrilycoop from '../assets/landing/projects/agrilycoop-logo.png';
import LogoCooplab from '../assets/landing/projects/cooplab-logo.png';
import LogoInvenboost from '../assets/landing/projects/invenboost-logo.png';
import LogoSil from '../assets/landing/projects/sil-logo.png';
import LogoVcu from '../assets/landing/projects/vcu-logo.png';

export const ABOUT_ME = [
    {
        title: '4+ years of professional experience',
        icon: <BsFillSuitcaseFill />,
    },
    {
        title: '42 Network graduate',
        icon: <></>,
    },
    {
        title: 'Skilled on team management',
        icon: <></>,
    },
    {
        title: '4+ years of professional experience',
        icon: <></>,
    },
    {
        title: '4+ years of professional experience',
        icon: <></>,
    },
];

export const PROJECTS = [
    {
        title: 'value chain',
        image: ImageVcu,
        logo: LogoVcu,
        url: 'https://valuechain-sil.ma/',
    },
    {
        title: 'social innovation lab',
        image: ImageSil,
        logo: LogoSil,
        url: 'https://sil.um6p.ma/',
    },
    {
        title: 'invenboost',
        image: ImageInvenboost,
        logo: LogoInvenboost,
        url: 'https://invenboost.com/',
    },
    {
        title: 'agrilycoop',
        image: ImageAgrilycoop,
        logo: LogoAgrilycoop,
        url: 'https://agrilycoop.ma/',
    },
    {
        title: 'cooplab',
        image: ImageCooplab,
        logo: LogoCooplab,
        url: 'https://www.cooplab.ma/',
    },
];

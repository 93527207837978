export const NAVBAR_PAGES = [
    {
        title: 'Latest Project',
        id: 'projects-i-made',
    },
    // {
    //     title: 'Activités',
    //     id: 'activites',
    // },
    // {
    //     title: 'SIL en chiffre',
    //     id: 'le-sil-en-chiffre',
    // },
    // {
    //     title: 'Méthodologie',
    //     id: 'notre-methodologie',
    // },
];
